import { useState, useEffect } from "react";

export function SavedWallets({
  setWalletAddress,
}: {
  setWalletAddress: (address: string) => void;
}) {
  const [walletAddressHistory, setWalletAddressHistory] = useState<string[]>([]);
  const [penguinSrc, setPenguinSrc] = useState("/imgs/fp_penguin_idle.png");

  useEffect(() => {
    const storedAddresses = localStorage.getItem("walletAddresses");
    if (storedAddresses) {
      setWalletAddressHistory(JSON.parse(storedAddresses));
    }
  }, []);

  const handleSelectAddress = (address: string) => {
    setWalletAddress(address);
  };

  const handleRemoveAddress = (addressToRemove: string) => {
    const updatedAddresses = walletAddressHistory.filter((address) => address !== addressToRemove);
    localStorage.setItem("walletAddresses", JSON.stringify(updatedAddresses));
    setWalletAddressHistory(updatedAddresses);
  };

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div className="flex flex-col items-center justify-center mt-2 rounded-lg border-2 border-brown bg-white p-3 [box-shadow:inset_0_-2px_#342e2e] w-[22.5rem]">
        <div className="flex flex-col items-center justify-center">
          <a href={`https://frenpet.xyz/`} target="_blank" rel="noreferrer">
            <img src={penguinSrc} alt="" className="h-32 w-36" />
          </a>
          <h1 className="font-bold">Fren Pet Analytica</h1>
          <div className="flex flex-row ml-1">
            <img src="/imgs/paw.svg" alt="" className="mr-[0.7rem] mt-2 h-4 w-4" />
            
            <h2 className="text-sm font-semibold mt-2">Your Fren Pet data</h2>
            
            <img src="/imgs/paw.svg" alt="" className="mr-[0.3rem] ml-1 mt-2 h-4 w-4" />
          </div>
        </div>
       
          <p className="text-sm mt-2  font-semibold text-center">
            Select a wallet
          </p>
      <div className="mt-3 space-y-3 mb-2">
        {walletAddressHistory.map((address) => (
          <div
            key={address}
            className="flex justify-between items-center border border-brown rounded-md px-4 py-2 hover:bg-brown hover:text-white cursor-pointer"
            onClick={() => handleSelectAddress(address)}
          >
            <span className="truncate w-56">{address}</span>
            <button
             className="rounded-md border-2 border-brown bg-white px-2 text-brown hover:bg-green hover:text-white"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveAddress(address);
              }}
            >
               <img src="/imgs/x.svg" alt="" className="h-6 w-6" />
            </button>
          </div>
        ))}
      </div>
      </div>
      
    </div>
  );
}
