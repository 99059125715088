import { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from "react-router-dom";
import {
  Pet,
  GasPrice,
  FpPrice,
  Reward,
  Totals,
  Score,
  PetRewards,
  PetScores,
  EthPrice,
  PGoldPrice,
  PGoldBalance,
  TreasuryBalance,
  EthGasPrice
} from "../common/types";
import {
  getTopPets,
  getGasPrices,
  getEthGasPrices,
  getAllCats,
  getAllDragons,
  getFpPrices,
  getTopOldPets,
  getEthBalance,
  getFpBalance,
  wssURL,
  getEthPrices,
  getTopListings,
  getCacheExpiryTime,
  getPotionStats,
  getPgoldPrices,
  getSpeciesCounts,
  getTopStarsLeaderboard,
  getAllPandas,
  getAllSheep,
  getAllMonkeys,
  getAllDogs,
  getAllPenguins,
  getAllPepes,
  getTreasuryBalances,
} from "./helpers/utils";
import { PetStatOwnerCard } from "./components/cards/PetStatOwnerCard";
import { TopBar } from "./components/utils/TopBar";
import { PetTimerCard } from "./components/cards/PetTimerCard";
import { Charts } from "./components/views/Charts";
import { ActivityFeed } from "./components/views/ActivityFeed";
import { EmptyState } from "./components/views/EmptyState";
import { Legend } from "./components/views/Legend";
import { Donate } from "./components/views/Donate";
import { PetStore } from "./components/views/PetStore";
import { calcCarrotBalance, calculateStaked, refreshPetsAndTotals, blacklistedAddresses, calculateTransactionCostInUsd } from "./helpers/helpers";
import { utils } from "web3";
import { Stats } from "./components/views/Stats";
import { Changelog } from "./components/views/Changelog";
import { PetLeaderboard } from "./components/leaderboards/PetLeaderboard";
import { PetPassCard } from "./components/cards/PetPassCard";
import { Profile } from "./components/views/Profile";
import { SavedWallets } from "./components/views/SavedWallets";

const VERSION = "1.1";

function App() {
  return (
    <Router> 
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/:walletAddress" element={<Main />} />
      </Routes>
    </Router>
  );
}

function Main() {
  const navigate = useNavigate();
  const { walletAddress } = useParams();

  const [pets, setPets] = useState<Pet[]>([]);
  const [topPets, setTopPets] = useState<Pet[]>([]);
  const [topCats, setTopCats] = useState<Pet[]>([]);
  const [topDragons, setTopDragons] = useState<Pet[]>([]);
  const [topPepes, setTopPepes] = useState<Pet[]>([]);
  const [topSheep, setTopSheep] = useState<Pet[]>([]);
  const [topMonkeys, setTopMonkeys] = useState<Pet[]>([]);
  const [topPandas, setTopPandas] = useState<Pet[]>([]);
  const [topDogs, setTopDogs] = useState<Pet[]>([]);
  const [topPenguins, setTopPenguins] = useState<Pet[]>([]);
  const [topOldPets, setTopOldPets] = useState<Pet[]>([]);
  const [topStars, setTopStars] = useState<Pet[]>([]);
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [petRewards, setPetRewards] = useState<PetRewards>({});
  const [scores, setScores] = useState<Score[]>([]);
  const [petScores, setPetScores] = useState<PetScores>({});
  const [totals, setTotals] = useState<Totals>({
    totalRewards: 0,
    totalPets: 0,
    totalScore: 0,
    totalFpOwed: 0,
    totalFpPerSecond: 0,
    totalStars: 0,
    totalMonsters: 0,
  });
  const [topListings, setTopListings] = useState<Pet[]>([]);
  const [potionStats, setPotionStats] = useState<any>(null);
  const [selectedViewer, setSelectedViewer] = useState("timerViewer");
  const [gasPrices, setGasPrices] = useState<GasPrice[]>([]);
  const [ethGasPrices, setEthGasPrices] = useState<EthGasPrice[]>([]);
  const [fpPrices, setFpPrices] = useState<FpPrice[]>([]);
  const [ethPrices, setEthPrices] = useState<EthPrice[]>([]);
  const [pGoldPrices, setPGoldPrices] = useState<PGoldPrice[]>([]);
  const [walletAddressState, setWalletAddressState] = useState<string>("");
  const [balancesViewIndex, setBalancesViewIndex] = useState(0);
  const [pricesViewIndex, setPricesViewIndex] = useState(0);
  const [statsViewIndex, setStatsViewIndex] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [sortKey, setSortKey] = useState<keyof Pet>("score");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [ethBalance, setEthBalance] = useState<string>("");
  const [pGoldBalance, setPgoldBalance] = useState<PGoldBalance[]>([]);
  const [latestPgoldBalance, setlatestPgoldBalance] = useState<PGoldBalance | null>(null);
  const [fpBalance, setFpBalance] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [hibernatedData, setHibernatedData] = useState<any[]>([]);
  const [bonkData, setBonkData] = useState<any[]>([]);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [isWalletDataLoaded, setIsWalletDataLoaded] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [cacheExpiryTime, setCacheExpiryTime] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [startXContent, setStartXContent] = useState<number | null>(null);
  const cacheExpiryIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [speciesCounts, setSpeciesCounts] = useState<{ species: string; count: number }[] | null>(null);
  const [treasuryBalances, setTreasuryBalances] = useState<TreasuryBalance[]>([]);
  const [isWalletSelected, setIsWalletSelected] = useState<boolean>(false);

  const hideOnView = [
    "toppets",
    "charts",
    "topcats",
    "activityfeed",
    "legend",
    "donate",
    "topoldpets",
    "store",
    "stats",
    "changelog",
    "topstars",
    "topdragons",
    "toppepes",
    "topsheep",
    "toppandas",
    "topmonkeys",
    "topdogs",
    "toppenguins",
    "profile"
  ].includes(selectedViewer);

  const SWIPE_THRESHOLD = 50;

  const ethPrice = ethPrices.length > 0 ? ethPrices[ethPrices.length - 1].eth_price : 0;
  const pGoldPrice = pGoldPrices.length > 0 ? pGoldPrices[pGoldPrices.length - 1].pgold_price : 0;
  const fpPrice = fpPrices.length > 0 ? fpPrices[fpPrices.length - 1].fp_price : 0;

  const gasPriceGwei = gasPrices[gasPrices.length - 1]?.gas_price || 0;
  const ethGasPriceGwei = ethGasPrices[ethGasPrices.length - 1]?.eth_gas_price || 0;
  const ethPriceUsd = ethPrices[ethPrices.length - 1]?.eth_price || 0;

  const transactionCostUsd = calculateTransactionCostInUsd(
    gasPriceGwei,
    ethPriceUsd,
    21000,
  ).toFixed(3);

  const totalRewardsInUsd = (totals.totalRewards * ethPrice).toFixed(2);
  const ethBalanceFloat = parseFloat(ethBalance);
  const totalEthBalInUsd = isNaN(ethBalanceFloat) ? 0 : (ethBalanceFloat * ethPrice).toFixed(2);
  const fpBalanceFloat = parseFloat(fpBalance);
  const fpBalanceInUsd = isNaN(fpBalanceFloat) ? 0 : (fpBalanceFloat * fpPrice).toFixed(2);
  const totalStakedFp = calculateStaked(totals.totalFpPerSecond);
  const totalStakedFpInUsd = (totalStakedFp * fpPrice).toFixed(2);
  const totalCarrots = pets.reduce((total, pet) => {
    return total + calcCarrotBalance(pet.carrotsBalance, pet.carrotsTimestamp);
  }, 0);
  const pGoldBalanceFloat = parseFloat(latestPgoldBalance?.pgold_balance.toString() || "0");
  const totalPGoldBalInUsd = isNaN(pGoldBalanceFloat) || fpPrice === 0 || ethPrice === 0 
  ? 0 
  : (((1 / pGoldPrice) * fpPrice) * pGoldBalanceFloat).toFixed(2);
  const totalPGoldBalInFp = isNaN(pGoldBalanceFloat) || fpPrice === 0 || ethPrice === 0 
  ? 0 
  : ((1 / pGoldPrice) * pGoldBalanceFloat).toFixed(2);
  const sortKeyRef = useRef(sortKey);
  const sortOrderRef = useRef(sortOrder);

  
  useEffect(() => {
    if (walletAddress) {
      try {
        const checksummedAddress = utils.toChecksumAddress(walletAddress);
        if (blacklistedAddresses.has(checksummedAddress)) {
          navigate("/"); 
          return;
        }
      } catch (error) {
        navigate("/");
      }
    }
  }, [walletAddress, navigate]);


  useEffect(() => {
    if (walletAddress && isWalletSelected) {
      getEthBalance(walletAddress).then((balance) => setEthBalance(parseFloat(balance).toFixed(4)));
      getFpBalance(walletAddress).then((balance) => setFpBalance(parseFloat(balance).toFixed(2)));
      getTopListings().then((data) => setTopListings(data));
      getPotionStats().then((data) => {
        setPotionStats(data);
      });
      getSpeciesCounts().then((data) => {
        if (data) {
          const transformedData = Object.entries(data).map(([species, count]) => ({ species, count }));
          setSpeciesCounts(transformedData);
        } else {
          setSpeciesCounts(null);
        }
      });
    }
  }, [walletAddress, isWalletSelected]);
  

  const incrementBalancesViewIndex = () => {
    setBalancesViewIndex((prevIndex) => (prevIndex + 1) % 11);
  };

  const decrementBalancesViewIndex = () => {
    setBalancesViewIndex((prevIndex) => (prevIndex === 0 ? 10 : prevIndex - 1));
  };

  const incrementPricesViewIndex = () => {
    setPricesViewIndex((prevIndex) => (prevIndex + 1) % 5);
  };

  const decrementPricesViewIndex = () => {
    setPricesViewIndex((prevIndex) => (prevIndex === 0 ? 4 : prevIndex - 1));
  };

  const incrementStatsViewIndex = () => {
    setStatsViewIndex((prevIndex) => (prevIndex + 1) % 5);
  };

  const decrementStatsViewIndex = () => {
    setStatsViewIndex((prevIndex) => (prevIndex === 0 ? 4 : prevIndex - 1));
  };

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    decrementBalancesViewIndex();
  };

  const handlePricesMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    decrementPricesViewIndex();
  };

  const handleStatsMenu =  (e: React.MouseEvent) => {
    e.preventDefault();
    decrementStatsViewIndex();
  };

  const handleWalletAddressChange = (address: string) => {
    setWalletAddressState(address);
    setIsWalletSelected(true); 
    navigate(`/${address}`);
  };


  const handleContentTouchStart = (e: TouchEvent) => {
    const touchStartX = e.touches[0].clientX;
    setStartXContent(touchStartX);
  };

  const handleContentTouchMove = (e: TouchEvent) => {
    const touchEndX = e.touches[0].clientX;
    if (startXContent !== null) {
      const diffX = startXContent - touchEndX;
      if (diffX > SWIPE_THRESHOLD) {
        incrementBalancesViewIndex();
        setStartXContent(null);
      } else if (diffX < -SWIPE_THRESHOLD) {
        decrementBalancesViewIndex();
        setStartXContent(null);
      }
    }
  };

  const handleContentTouchEnd = () => {
    setStartXContent(null);
  };

  function setupWebSocket() {
    const newWs = new WebSocket(wssURL);
    newWs.onopen = () => {
      console.log("Connected to WebSocket");
      setWs(newWs);
    };
    newWs.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "ownerData") {
        const walletAddress = message.data.wallet;
        setIsLoading(true);
        refreshPetsAndTotals(
          walletAddress,
          sortKeyRef.current,
          sortOrderRef.current,
          setPets,
          setRewards,
          setScores,
          setTotals,
          setPetRewards,
          setPetScores,
          setEthBalance,
          setPgoldBalance,
          setlatestPgoldBalance,
          setFpBalance,
          message.data,
        );
        setIsLoading(false);
        setIsWalletDataLoaded(true);
      } else if (message.type === "hibernated" || message.type === "killed") {
        setHibernatedData((prevData) => [...prevData, message.data]);
      } else if (message.type === "attack") {
        setBonkData((prevData) => [...prevData, message.data]);
      } else if (message.type === "sessionId") {
        setSessionId(message.data);
        console.log("Assigned sessionId:", message.data);
      } else if (message.type === "loading") {
        setIsLoading(true);
      }
    };
    newWs.onclose = () => {
      console.log("WebSocket closed");
      setTimeout(() => {
        setupWebSocket();
      }, 1000);
    };
  }

  useEffect(() => {
    getGasPrices().then(setGasPrices);
    const gasInterval = setInterval(() => {
      getGasPrices().then(setGasPrices);
    }, 60000);

    getEthGasPrices().then(setEthGasPrices);
    const ethGasInterval = setInterval(() => {
      getEthGasPrices().then(setEthGasPrices);
    }, 60000);

    getFpPrices().then(setFpPrices);
    const fpInterval = setInterval(() => {
      getFpPrices().then(setFpPrices);
    }, 60000);

    getEthPrices().then(setEthPrices);
    const ethInterval = setInterval(() => {
      getEthPrices().then(setEthPrices);
    }, 60000);

    getPgoldPrices().then(setPGoldPrices);
    const pGoldInterval = setInterval(() => {
      getPgoldPrices().then(setPGoldPrices);
    }, 60000);

    getTreasuryBalances().then(setTreasuryBalances);
    const treasuryInterval = setInterval(() => {
      getTreasuryBalances().then(setTreasuryBalances);
    }, 60000);
    

    return () => {
      clearInterval(gasInterval);
      clearInterval(fpInterval);
      clearInterval(ethInterval);
      clearInterval(pGoldInterval);
      clearInterval(treasuryInterval);
      clearInterval(ethGasInterval);
    };
  }, []);

  useEffect(() => {
    if (isWalletDataLoaded) {
      setIsLoading(false);
      getTopOldPets().then((data) => setTopOldPets(data));
      getTopPets().then((data) => setTopPets(data));
      getAllCats().then((data) => setTopCats(data));
      getTopStarsLeaderboard().then((data) => setTopStars(data));
      getAllDragons().then((data)=> setTopDragons(data));
      getAllPandas().then((data)=> setTopPandas(data));
      getAllSheep().then((data)=> setTopSheep(data));
      getAllMonkeys().then((data)=> setTopMonkeys(data));
      getAllDogs().then((data)=> setTopDogs(data));
      getAllPenguins().then((data)=> setTopPenguins(data));
      getAllPepes().then((data)=>setTopPepes(data));
    }
  }, [isWalletDataLoaded]);

  useEffect(() => {
    if (walletAddress && isWalletSelected) {
      const checksummedWallet = utils.toChecksumAddress(walletAddress);
      if (blacklistedAddresses.has(checksummedWallet)) return;
  
      setupWebSocket();
      setIsLoading(true);
    }
  }, [walletAddress, isWalletSelected]);  

  useEffect(() => {
    if (walletAddress && ws) {
      if (!sessionId) {
        ws.send(JSON.stringify({ type: "getSessionId", version: VERSION }));
        return;
      }
      console.log("Sending wallet address to WebSocket:", walletAddress);
      setIsLoading(true);
      ws.send(
        JSON.stringify({ type: "mainWallet", wallet: walletAddress, version: VERSION, sessionId }),
      );
    }
  }, [walletAddress, ws, sessionId]);

  useEffect(() => {
    if (refreshTrigger > 0 && ws && ws.readyState === WebSocket.OPEN && sessionId) {
      setIsLoading(true);
      console.log("Refreshing via ws:", walletAddress);
      ws.send(JSON.stringify({ type: "refresh", wallet: walletAddress, version: VERSION, sessionId}));
    }
  }, [refreshTrigger, ws, sessionId, walletAddress]);  

  useEffect(() => {
    setSortKey("score");
    setSortOrder("desc");
  }, [walletAddress]);

  useEffect(() => {
    sortKeyRef.current = sortKey;
    sortOrderRef.current = sortOrder;
  }, [sortKey, sortOrder]);

  useEffect(() => {
    window.addEventListener("touchstart", handleContentTouchStart);
    window.addEventListener("touchmove", handleContentTouchMove);
    window.addEventListener("touchend", handleContentTouchEnd);
    return () => {
      window.removeEventListener("touchstart", handleContentTouchStart);
      window.removeEventListener("touchmove", handleContentTouchMove);
      window.removeEventListener("touchend", handleContentTouchEnd);
    };
  }, [startXContent]);

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth <= 500);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchCacheExpiryTime = () => {
      getCacheExpiryTime().then((expiryTime) => {
        setCacheExpiryTime(expiryTime);
        let timeLeft = expiryTime / 1000;
        setRemainingTime(timeLeft);
        const expiryInSeconds = expiryTime / 1000;

        if (expiryInSeconds === cacheExpiryTime / 1000) {
          setTimeout(() => fetchCacheExpiryTime(), 5000);
          return;
        }
  
        setCacheExpiryTime(expiryTime);
        setRemainingTime(expiryInSeconds);
  
        if (cacheExpiryIntervalRef.current) {
          clearInterval(cacheExpiryIntervalRef.current);
        }
  
        cacheExpiryIntervalRef.current = setInterval(() => {
          setRemainingTime((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(cacheExpiryIntervalRef.current!);
              fetchCacheExpiryTime();
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);
      });
    };
  
    fetchCacheExpiryTime();
  
    return () => {
      if (cacheExpiryIntervalRef.current) {
        clearInterval(cacheExpiryIntervalRef.current);
      }
    };
  }, []);

  return (
    <div className="h-full w-full bg-green font-sans">
      <TopBar
        pets={pets}
        setPets={setPets}
        selectedViewer={selectedViewer}
        setSelectedViewer={setSelectedViewer}
        walletAddress={walletAddressState}
        setWalletAddress={handleWalletAddressChange}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setRefreshTrigger={setRefreshTrigger}
        sortKey={sortKey}
        sortOrder={sortOrder}
        setSortKey={setSortKey}
        setSortOrder={setSortOrder}
        isPhone={isPhone}
        fpPrices={fpPrices}
        ethPrices={ethPrices}
      />
      <div className="overflow-y-auto overflow-x-hidden">
        <div className="mt-[7.5rem] flex flex-col items-center">
          { pets.length === 0 &&
          (selectedViewer === "timerViewer" ||
            selectedViewer === "statViewer" ||
            selectedViewer === "petPasses" ||
            selectedViewer === "charts" || 
            selectedViewer === "profile")  ? (
              !isWalletSelected && localStorage.getItem("walletAddresses") &&
              JSON.parse(localStorage.getItem("walletAddresses")!).length >= 2 ? (
                <SavedWallets setWalletAddress={handleWalletAddressChange} />
              ) : (
                <EmptyState isLoading={isLoading} />
              )
          ) : (
            <>
              {!hideOnView && (
                <div className="flex flex-col justify-center bg-green text-sm min-h-6 max-h-6">
                  <div className="flex flex-row justify-center">
                    <div className="ml-8 mr-6 w-[3.5rem] flex" onClick={incrementStatsViewIndex} onContextMenu={handleStatsMenu}>
                      {statsViewIndex === 0 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/paw.svg" alt="" className="ml-[.1rem] mt-[.06rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">{totals.totalPets}</div>
                        </div>
                      ) : statsViewIndex === 1 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/piggy-bank.svg"
                              alt=""
                              className="mr-[0.15rem] ml-[.1rem] mt-[.03rem] h-4 w-4"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            {totals.totalScore.toFixed(0)}
                          </div>
                        </div>
                      ) : statsViewIndex === 2 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/carrot.svg"
                              alt=""
                              className="mr-[0.2rem] ml-[.1rem] mt-[.025rem] h-4 w-4"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            {totalCarrots ? totalCarrots.toFixed(2) : 0}
                          </div>
                        </div>
                      ): statsViewIndex === 3 ? (
                      <div className="flex max-w-28 items-center">
                        <div className="w-5">
                          <img src="/imgs/pokeball.svg" alt="" className="mt-[.15rem] h-5 w-5" />
                        </div>
                        <div className="ml-[.1rem] mt-[.1rem]">•</div>
                        <div className="ml-[.2rem] mt-[.1rem]">{totals.totalMonsters}</div>
                      </div>
                      ) : statsViewIndex === 4 ? (
                          <div className="flex max-w-28 items-center">
                            <div className="w-5">
                              <img
                                src="/imgs/star.svg"
                                alt=""
                                className="mt-[.06rem] ml-[.12rem] h-4 w-4"
                              />
                            </div>
                            <div className="ml-[.1rem] mt-[.1rem]">•</div>
                            <div className="ml-[.2rem] mt-[.1rem]">{totals.totalStars}</div>
                          </div>
                        ) : null}
                    </div>
                    <div
                      className="ml-9 flex w-[7.9rem]"
                      onClick={incrementBalancesViewIndex}
                      onContextMenu={handleContextMenu}
                    >
                      {balancesViewIndex === 0 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/treasure.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem] h-5 w-5"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">{totals.totalRewards} </div>
                          <img
                            src="/imgs/ethereum.svg"
                            alt=""
                            className="mt-[0.1rem] h-[.7rem] w-[.7rem]"
                          />
                        </div>
                      ) : balancesViewIndex === 1 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/treasure.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem] h-5 w-5"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]"> ${totalRewardsInUsd} </div>
                        </div>
                      ) : balancesViewIndex === 2 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/wallet.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem]  h-4 w-4"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">{ethBalance}</div>
                          <img
                            src="/imgs/ethereum.svg"
                            alt=""
                            className="mt-[0.1rem] h-[.7rem] w-[.7rem]"
                          />
                        </div>
                      ) : balancesViewIndex === 3 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/wallet.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem] h-4 w-4"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]"> ${totalEthBalInUsd}</div>
                        </div>
                      ) : balancesViewIndex === 4 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/frog.svg" alt="" className=" mt-[.15rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            {fpBalance}
                          </div>
                        </div>
                      ) : balancesViewIndex === 5 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/frog.svg" alt="" className=" mt-[.15rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            ${fpBalanceInUsd}
                          </div>
                        </div>
                      ) : balancesViewIndex === 6 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/frog.svg" alt="" className=" mt-[.15rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            {totals.totalFpOwed.toFixed(0)} | {" "}
                            {totalStakedFp}
                          </div>
                        </div>
                      ) : balancesViewIndex === 7 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img src="/imgs/frog.svg" alt="" className=" mt-[.15rem] h-4 w-4" />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">
                            ${totalStakedFpInUsd}
                          </div>
                        </div>
                      ) : balancesViewIndex === 8 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/coin-bag.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem]  h-5 w-5"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">{pGoldBalanceFloat}</div>
                        </div>
                      ) : balancesViewIndex === 9 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/coin-bag.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem]  h-5 w-5"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem] flex flex-row"> 
                            {totalPGoldBalInFp} 
                            <img
                            src="/imgs/frog.svg"
                            alt=""
                            className="mt-[0.3rem] h-[.7rem] w-[.7rem] ml-1"
                          /> </div>
                        </div>
                      )  : balancesViewIndex === 10 ? (
                        <div className="flex max-w-28 items-center">
                          <div className="w-5">
                            <img
                              src="/imgs/coin-bag.svg"
                              alt=""
                              className="mr-[0.35rem] mt-[.15rem]  h-5 w-5"
                            />
                          </div>
                          <div className="ml-[.1rem] mt-[.1rem]">•</div>
                          <div className="ml-[.2rem] mt-[.1rem]">${totalPGoldBalInUsd}</div>
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="ml-2 flex w-[6.6rem] justify-start"
                      onClick={incrementPricesViewIndex}
                      onContextMenu={handlePricesMenu}
                    >
                      {pricesViewIndex === 0 ? (
                        gasPrices.length > 0 && (
                          <div className="flex max-w-28 items-center">
                            <img src="/imgs/gas.svg" alt="" className="mr-[0.25rem] mt-[.1rem] h-4 w-4" />
                            •
                            <div className="ml-[.3rem] mt-[.1rem]">
                              {gasPrices[gasPrices.length - 1].gas_price.toFixed(4)}
                            </div>
                          </div>
                        )
                      ) : pricesViewIndex === 1 ? (
                        <div className="flex max-w-28 items-center">
                          <img src="/imgs/gas.svg" alt="" className="mr-[0.25rem] mt-[.1rem] h-4 w-4" />•
                          <div className="ml-[.3rem] mt-[.1rem]">
                            ${transactionCostUsd}
                          </div>
                        </div>
                      )  : pricesViewIndex === 2 ? (
                        <div className="flex max-w-28 items-center">
                          <img src="/imgs/frog.svg" alt="" className="mr-[0.25rem] mt-[.1rem] h-4 w-4" />•
                          <div className="ml-[.3rem] mt-[.1rem]">
                            ${fpPrices[fpPrices.length - 1].fp_price.toFixed(2)}
                          </div>
                        </div>
                      ) : pricesViewIndex === 3 ? (
                        <div className="flex max-w-28 items-center">
                          <img src="/imgs/ethereum.svg" alt="" className="mr-[0.15rem] mt-[.075rem] h-4 w-4" />•
                          <div className="ml-[.3rem] mt-[.1rem]">
                            ${ethPrices[ethPrices.length - 1].eth_price.toFixed()}
                          </div>
                        </div>
                      ) : pricesViewIndex === 4 ? (
                        <div className="flex max-w-28 items-center">
                          <img src="/imgs/ethereum.svg" alt="" className="mr-[0.15rem] mt-[.075rem] h-4 w-4" />•
                          <div className="ml-[.3rem] mt-[.1rem] flex">
                            {ethGasPrices[ethGasPrices.length - 1].eth_gas_price.toFixed(2)} <img src="/imgs/gas.svg" alt="" className="ml-[0.25rem] mt-[.22rem] h-3 w-3" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
              <div className="mx-auto mb-8 flex w-screen flex-row flex-wrap items-center justify-center overflow-x-hidden">
                {selectedViewer === "timerViewer" &&
                  pets.map((pet) => (
                    <PetTimerCard key={pet.id} pet={pet} walletAddress={walletAddressState} />
                  ))}
                {selectedViewer === "statViewer" &&
                  pets.map((pet) => (
                    <PetStatOwnerCard key={pet.id} pet={pet} walletAddress={walletAddressState} />
                  ))}
                {selectedViewer === "petPasses" &&
                  pets
                    .filter((pet) => pet.passHolder)
                    .sort((a, b) => b.score - a.score)
                    .map((pet) => (
                      <PetPassCard key={pet.id} pet={pet} walletAddress={walletAddressState} />
                    ))}

                {selectedViewer === "charts" && (
                  <Charts
                    rewards={rewards}
                    scores={scores}
                    petRewards={petRewards}
                    petScores={petScores}
                    pGoldBalance={pGoldBalance}
                    fpPrices={fpPrices}
                    pGoldPrices={pGoldPrices}
                  />
                )}

                {selectedViewer === "stats" && 
                  <Stats 
                  speciesCounts={speciesCounts} 
                  walletAddress={walletAddressState}  
                  pGoldPrices={pGoldPrices}
                  isPhone={isPhone}  
                  fpPrices={fpPrices} 
                  ethPrices={ethPrices}
                  gasPrices={gasPrices}
                  treasuryBalances={treasuryBalances}
                  />
                }
                {selectedViewer === "toppets" && (<PetLeaderboard topPets={topPets} speciesCounts={speciesCounts} selectedViewer="toppets" />)}
                {selectedViewer === "topoldpets" && (<PetLeaderboard topPets={topOldPets} speciesCounts={speciesCounts} selectedViewer="topoldpets" />)}
                {selectedViewer === "topdogs" && (<PetLeaderboard topPets={topDogs} speciesCounts={speciesCounts} selectedViewer="topdogs" />)}
                {selectedViewer === "topsheep" && (<PetLeaderboard topPets={topSheep} speciesCounts={speciesCounts} selectedViewer="topsheep" />)}
                {selectedViewer === "topmonkeys" && (<PetLeaderboard topPets={topMonkeys} speciesCounts={speciesCounts} selectedViewer="topmonkeys" />)}
                {selectedViewer === "toppandas" && (<PetLeaderboard topPets={topPandas} speciesCounts={speciesCounts} selectedViewer="toppandas" />)}
                {selectedViewer === "toppepes" && (<PetLeaderboard topPets={topPepes} speciesCounts={speciesCounts} selectedViewer="toppepes" />)}
                {selectedViewer === "toppenguins" && (<PetLeaderboard topPets={topPenguins} speciesCounts={speciesCounts} selectedViewer="toppenguins" />)}
                {selectedViewer === "topcats" && (<PetLeaderboard topPets={topCats} speciesCounts={speciesCounts} selectedViewer="topcats" />)}
                {selectedViewer === "topdragons" && (<PetLeaderboard topPets={topDragons} speciesCounts={speciesCounts} selectedViewer="topdragons" />)}
                {selectedViewer === "topstars" && (<PetLeaderboard topPets={topStars} speciesCounts={speciesCounts} selectedViewer="topstars" />)}


                {selectedViewer === "activityfeed" && (<ActivityFeed hibernatedData={hibernatedData} bonkData={bonkData} />)}
                {selectedViewer === "store" && <PetStore topListings={topListings} potionStats={potionStats} isPhone={isPhone} remainingTime={remainingTime} walletAddress={walletAddressState}/>}

                {selectedViewer === "legend" && <Legend />}
                {selectedViewer === "donate" && <Donate />}
                {selectedViewer === "changelog" && <Changelog />}
                {selectedViewer === "profile" && (
                  <Profile
                  totals={totals}
                  totalCarrots={totalCarrots.toString()}
                  totalRewards={totals.totalRewards.toString()}
                  totalRewardsInUsd={totalRewardsInUsd.toString()}
                  totalEthBalInUsd={totalEthBalInUsd.toString()}
                  fpBalance={fpBalance.toString()}
                  fpBalanceInUsd={fpBalanceInUsd.toString()}
                  totalStakedFp={totalStakedFp.toString()}
                  totalStakedFpInUsd={totalStakedFpInUsd.toString()}
                  pGoldBalanceFloat={pGoldBalanceFloat.toString()}
                  totalPGoldBalInFp={totalPGoldBalInFp.toString()}
                  totalPGoldBalInUsd={totalPGoldBalInUsd.toString()}
                  ethBalance={ethBalance.toString()}
                  walletAddress={walletAddressState}
                  pets={pets}
                  speciesCounts={speciesCounts} 
                  pet={pets.length > 0 ? pets[0] : null}
                />                
                )}

              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
